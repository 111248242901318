<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <QnaWriteForm
        v-if="pageData"
        :page-data="pageData"
        :reply-data="replyData"
        @reply-reload="replyReload"
      />
    </Layout>
  </div>
</template>

<script>
import Vue from 'vue';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header.vue';
import QnaWriteForm from '@/components/qna/QnaWriteForm.vue';

export default Vue.extend({
  components: {
    Layout,
    PageHeader,
    QnaWriteForm
  },
  async created() {
    await this.$store
      .dispatch('qna/getQnaData', {
        qnaId: this.$route.params.idx
      })
      .then(res => {
        this.responseData = res.data.data;
      });

    // reply
    await this.$store
      .dispatch('qna/replyList', {
        qnaId: this.$route.params.idx
      })
      .then(res => {
        this.replyData = res.list;
        console.log(this.replyData);
      });
  },
  computed: {
    pageData() {
      return this.responseData;
    }
  },
  data() {
    return {
      title: 'Qna Modify',
      items: [
        {
          text: 'Admin',
          href: '/'
        },
        {
          text: 'Qna',
          href: '/'
        },
        {
          text: 'Qna Modify',
          active: true
        }
      ],
      responseData: null,
      replyData: null
    };
  },
  methods: {
    async replyReload() {
      await this.$store
        .dispatch('qna/replyList', {
          qnaId: this.$route.params.idx
        })
        .then(res => {
          this.replyData = res.list;
        });
    }
  }
});
</script>
