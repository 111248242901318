<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">기본정보</h4>
          <form action="#" @submit.prevent="typeForm">
            <!-- row -->
            <div class="row">
              <!-- col -->
              <div class="col-md-4 col-sm-4">
                <div class="form-group mb-4">
                  <label>코스</label>
                  <select
                    v-if="courseData"
                    v-model="typeform.courseId"
                    class="form-control mg-r-5"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.courseId.$error
                    }"
                  >
                    <option v-for="item in courseData" :key="item.idx" :value="item.idx">
                      {{ item.name }}
                    </option>
                  </select>
                  <div v-if="typesubmit && $v.typeform.courseId.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.courseId.required">값을 선택해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-4">
                <div class="form-group mb-4">
                  <label>섹션</label>
                  <select
                    v-model="typeform.sectionId"
                    class="form-control mg-r-5"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.sectionId.$error
                    }"
                  >
                    <option v-for="item in sectionData" :key="item.idx" :value="item.idx">
                      {{ item.name }}
                    </option>
                  </select>
                  <div v-if="typesubmit && $v.typeform.sectionId.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.sectionId.required">값을 선택해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-4">
                <div class="form-group mb-4">
                  <label>강의</label>
                  <select
                    v-model="typeform.postId"
                    class="form-control mg-r-5"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.postId.$error
                    }"
                  >
                    <option v-for="item in postData" :key="item.idx" :value="item.idx">
                      {{ item.title }}
                    </option>
                  </select>
                  <div v-if="typesubmit && $v.typeform.postId.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.postId.required">값을 선택해주세요.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- col -->
              <div class="col-md-4 col-sm-4">
                <div class="form-group mb-4">
                  <label>작성자(닉네임)</label>
                  <input
                    v-model="typeform.mbName"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.mbName.$error
                    }"
                    disabled="true"
                  />
                  <div v-if="typesubmit && $v.typeform.mbName.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.mbName.required">
                      값을 입력해주세요.
                    </span>
                  </div>
                </div>
              </div>
              <!-- col -->
              <div class="col-md-4 col-sm-4">
                <div class="form-group mb-4">
                  <label>작성자(아이디)</label>
                  <input
                    v-model="typeform.mbId"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.mbId.$error
                    }"
                    disabled="true"
                  />
                  <div v-if="typesubmit && $v.typeform.mbId.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.mbId.required">
                      값을 입력해주세요.
                    </span>
                  </div>
                </div>
              </div>
              <!-- col -->
              <div class="col-md-4 col-sm-4">
                <div class="form-group mb-4">
                  <label>등록일</label>
                  <input
                    v-model="typeform.regDate"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.regDate.$error
                    }"
                  />
                  <div v-if="typesubmit && $v.typeform.regDate.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.regDate.required">
                      값을 입력해주세요.
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-4">
                <div class="form-group mb-4">
                  <label>오픈여부</label>
                  <select
                    v-model="typeform.openYn"
                    class="form-control mg-r-5"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.openYn.$error
                    }"
                  >
                    <option value="Y">Y</option>
                    <option value="N">N</option>
                  </select>
                  <div v-if="typesubmit && $v.typeform.openYn.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.openYn.required">
                      값을 입력해주세요.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- col -->
              <div class="col-md-12 col-sm-12">
                <div class="form-group mb-4">
                  <label>질문</label>
                  <input
                    v-model="typeform.title"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.title.$error
                    }"
                  />
                  <div v-if="typesubmit && $v.typeform.title.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.title.required">
                      값을 입력해주세요.
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-4">
                  <label>상세 내용</label>
                  <CkeditorNuxtBasic v-model="typeform.desc" :config="config" />
                </div>
              </div>
              <div class="col-12 text-center">
                <button class="btn btn-dark mr-2" @click="$router.go(-1)">목록으로</button>
                <button class="btn btn-primary" @click="goCommunity(pageData.idx)">바로가기</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <h5>답변수 {{ replyData.length }}</h5>
      <QnaReplyList
        v-for="item in replyData"
        :key="item.idx"
        :item="item"
        @reply-reload="$emit('reply-reload')"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import CkeditorNuxtBasic from '@/components/editor/CkeditorNuxtBasic.vue';
import QnaReplyList from '@/components/qna/QnaReplyList.vue';

export default Vue.extend({
  components: {
    CkeditorNuxtBasic,
    QnaReplyList
  },
  mixins: [validationMixin],
  props: {
    pageData: {
      type: Object,
      default() {
        return {};
      }
    },
    replyData: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      typeform: {
        qnaId: '',
        courseId: '',
        sectionId: '',
        postId: '',
        mbName: '',
        mbId: '',
        regDate: '',
        title: '',
        openYn: '',
        desc: ''
      },
      questionItem: [], // 오지선다 문제
      quizQuestionmodifyFlag: false, // 퀴즈 문제가 수정이 되면 전부 삭제하고 재 등록해야해서 플래그로 관리한다
      typesubmit: false,
      config: {
        codeBlock: {
          languages: [
            {language: 'css', label: 'CSS'},
            {language: 'html', label: 'HTML'},
            {language: 'javascript', label: 'JavaScript'},
            {language: 'python', label: 'Python'}
          ]
        },
        link: {
          // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
          addTargetToExternalLinks: true,

          // Let the users control the "download" attribute of each link.
          decorators: [
            {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'download'
              }
            }
          ]
        },
        image: {
          resizeUnit: '%',
          resizeOptions: [
            {
              name: 'resizeImage:original',
              value: null
            },
            {
              name: 'resizeImage:25',
              value: '25'
            },
            {
              name: 'resizeImage:50',
              value: '50'
            },
            {
              name: 'resizeImage:75',
              value: '75'
            }
          ],
          toolbar: [
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            'resizeImage'
          ]
        }
      },
      courseData: null,
      sectionData: null,
      postData: null
    };
  },
  async created() {
    await this.$store
      .dispatch('course/getCourseList', {
        page: 1,
        perPage: 10,
        order: 'asc'
      })
      .then(res => {
        this.courseData = res.data.list;
      });
  },
  watch: {
    async 'typeform.courseId'() {
      await this.$store
        .dispatch('section/getSectionList', {
          courseIdx: this.typeform.courseId
        })
        .then(res => {
          this.sectionData = res.list;
        });
    },
    async 'typeform.sectionId'() {
      // 특정 코스 > 섹션에 속한 게시글 제목
      await this.$store
        .dispatch('qna/getQnaSectionList', {
          courseId: this.typeform.courseId,
          sectionId: this.typeform.sectionId
        })
        .then(res => {
          this.postData = res.list;
          console.log(this.postData);
        });
    },
    'typeform.movieType'(val) {
      if (this.pageData?.url && this.pageData.url !== '') return;
      // if (val === 'vimeo') this.typeform.movieUrl = 'https://player.vimeo.com/video/';
      if (val === 'vimeo') this.typeform.movieUrl = '';
      if (val === 'youtube') this.typeform.movieUrl = 'https://www.youtube.com/embed/';
    }
  },
  mounted() {
    if (this.pageData.length !== 0 && Object.keys(this.pageData).length > 0) {
      this.typeform.qnaId = this.pageData.idx;
      this.typeform.courseId = this.pageData.cIdx;
      this.typeform.sectionId = this.pageData.sIdx;
      this.typeform.postId = this.pageData.pIdx;
      this.typeform.mbName = this.pageData.mbName;
      this.typeform.mbId = this.pageData.mbId;
      this.typeform.regDate = this.pageData.regdate;
      this.typeform.title = this.pageData.title;
      this.typeform.openYn = this.pageData.openYn;

      setTimeout(() => {
        this.typeform.desc = this.pageData.desc;
      }, 100);
    }

    console.log(this.replyData);
  },
  validations: {
    typeform: {
      courseId: {required},
      sectionId: {required},
      postId: {required},
      mbName: {required},
      mbId: {required},
      regdate: {required},
      title: {required},
      openYn: {required}
    }
  },
  methods: {
    async typeForm() {
      this.typesubmit = true;
      this.$v.typeform.$touch();

      if (!this.$v.$invalid) {
        await this.$store
          .dispatch('post/addPost', {
            qnaId: this.typeform.qnaId,
            courseId: this.typeform.courseId,
            sectionId: this.typeform.sectionId,
            postId: this.typeform.postId,
            mbName: this.typeform.mbName,
            orderNum: this.typeform.orderNum,
            openYn: this.typeform.openYn,
            title: this.typeform.title,
            content: this.typeform.content
          })
          .then(response => {
            if (response.data.result) {
              alert(response.data.message);
              if (this.typeform.postId === '') {
                // this.$router.push('/post');
              }
            } else {
              alert(response.data.message);
            }
          });
      }
    },
    editorInit: function(editor) {
      console.log('init');
      require('brace/ext/language_tools'); //language extension prerequsite...
      require('brace/mode/html');
      require('brace/mode/javascript'); //language
      require('brace/mode/less');
      require('brace/theme/monokai');
      require('brace/snippets/javascript'); //snippet
      require(['emmet/emmet'], function(data) {
        window.emmet = data.emmet;
      });
      editor.setOption('enableEmmet', true);
      editor.getSession().setUseWrapMode(true);
      editor.setOptions({
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        fontSize: 14,
        highlightActiveLine: true,
        // enableSnippets: true,
        showLineNumbers: true,
        tabSize: 2,
        showPrintMargin: false,
        showGutter: true
      });
    },
    dataSumit() {
      console.log('save');
    },
    addQuestionItem() {
      this.questionItem.push({
        value: '',
        list: []
      });
    },
    addQuestionListItem(index) {
      this.questionItem[index].list.push({
        value: '',
        hintMsg: '',
        isCorrect: 'wrong'
      });
    },
    removeListItem(index, listIndex) {
      this.questionItem[index].list = this.questionItem[index].list.filter(
        (v, i) => i !== listIndex
      );
    },
    sendingEvent(file, xhr, formData) {
      formData.append('postId', this.typeform.postId);
    },
    removeEvent(file) {
      this.$store.dispatch('post/removeRunImg', {
        fileName: file.name,
        postId: this.typeform.postId
      });
    },
    goCommunity(idx) {
      window.open('https://www.sucoding.kr/community/read/' + idx, '', '');
    }
  }
});
</script>
<style>
[disabled] {
  background-color: #f9f9f9 !important;
}
.vue-dropzone > .dz-preview .dz-remove {
  width: 100px;
  background: black;
  font-size: 10px;
  padding: 3px;
}
</style>
<style lang="scss" scoped>
.q-label {
  display: block;
  overflow: auto;
  line-height: 28px;

  button {
    float: right;
  }
}
ul,
ol,
li {
  padding: 0;
  list-style: none;
}

.question li {
  display: grid;
  grid-template-columns: 20px 1fr 1fr 100px 40px;
  row-gap: 10px;
  column-gap: 10px;
  padding: 10px 0;
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }
}
.mr-2 {
  margin-right: 10px;
}
</style>
