<template>
  <div class="reply">
    <div class="card">
      <div class="card-body">
        <div class="top">
          <div class="left">
            <avatar :username="item.mbName" :src="url"></avatar>
          </div>
          <div class="right">
            <strong>{{ item.mbName }}</strong>
            <p>{{ item.regdate }} / <i class="mdi mdi-thumb-up-outline"></i> {{ item.likeCnt }}</p>
          </div>
        </div>
        <div class="action">
          <span @click="modifyFlag = !modifyFlag">
            {{ modifyFlag ? '취소' : '수정' }}
          </span>
          <span @click="deleteItem('reply', item.idx)">삭제</span>
        </div>
        <div v-if="!modifyFlag" class="desc" v-html="modifyAction ? desc : item.desc"></div>

        <!-- 수정 에디터 -->
        <CkeditorNuxtBasic v-if="modifyFlag" v-model="desc" :config="config" />
        <div v-if="modifyFlag" class="text-center mt-3">
          <button class="btn btn-info" @click="replyModify">수정하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from 'vue-avatar';
import CkeditorNuxtBasic from '@/components/editor/CkeditorNuxtBasic.vue';

export default {
  components: {
    Avatar,
    CkeditorNuxtBasic
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      modifyFlag: false,
      modifyAction: false,
      desc: '',
      config: {
        removePlugins: ['MediaEmbedToolbar'],
        codeBlock: {
          languages: [
            {language: 'css', label: 'CSS'},
            {language: 'html', label: 'HTML'},
            {language: 'javascript', label: 'JavaScript'},
            {language: 'python', label: 'Python'}
          ]
        },
        link: {
          // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
          addTargetToExternalLinks: true,

          // Let the users control the "download" attribute of each link.
          decorators: [
            {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'download'
              }
            }
          ]
        },
        image: {
          resizeUnit: '%',
          resizeOptions: [
            {
              name: 'resizeImage:original',
              value: null
            },
            {
              name: 'resizeImage:25',
              value: '25'
            },
            {
              name: 'resizeImage:50',
              value: '50'
            },
            {
              name: 'resizeImage:75',
              value: '75'
            }
          ],
          toolbar: [
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            'resizeImage'
          ]
        }
      }
    };
  },
  computed: {
    url() {
      return this.item.profileName
        ? `https://img.sucoding.kr/${this.item.profilePath}/${this.item.profileName}`
        : '';
    }
  },
  created() {
    this.desc = this.item.desc;
  },
  methods: {
    async replyModify() {
      const result = await this.$store.dispatch('qna/replyModify', {
        replyId: this.item.idx,
        desc: this.desc
      });

      if (result.result) {
        this.modifyFlag = false;
        this.modifyAction = true;
      } else {
        this.$confirm({
          title: '오류',
          message: result.message,
          button: {
            yes: '확인'
          }
        });
      }
    },
    deleteItem(type, id) {
      this.$confirm({
        message: `정말 삭제하시겠습니까?`,
        button: {
          no: '취소',
          yes: '확인'
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: async confirm => {
          if (confirm) {
            await this.$store
              .dispatch('qna/deleteCommunityItem', {
                type,
                id
              })
              .then(res => {
                this.$confirm({
                  title: res.result ? `삭제되었습니다.` : 'Error',
                  message: res.message,
                  button: {
                    yes: '확인'
                  }
                });

                if (res.result) {
                  this.$emit('reply-reload');
                }
              });
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.reply {
  .top {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    .left {
      margin-right: 10px;
    }

    .right {
      p {
        margin-bottom: 0;
      }
    }
  }
  .action {
    position: absolute;
    top: 16px;
    right: 10px;

    span {
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .desc {
    padding: 1rem 0;
  }
}
</style>
