var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title mb-4"},[_vm._v("기본정보")]),_c('form',{attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.typeForm.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-sm-4"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("코스")]),(_vm.courseData)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.courseId),expression:"typeform.courseId"}],staticClass:"form-control mg-r-5",class:{
                    'is-invalid': _vm.typesubmit && _vm.$v.typeform.courseId.$error
                  },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "courseId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.courseData),function(item){return _c('option',{key:item.idx,domProps:{"value":item.idx}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e(),(_vm.typesubmit && _vm.$v.typeform.courseId.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.courseId.required)?_c('span',[_vm._v("값을 선택해주세요.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-4 col-sm-4"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("섹션")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.sectionId),expression:"typeform.sectionId"}],staticClass:"form-control mg-r-5",class:{
                    'is-invalid': _vm.typesubmit && _vm.$v.typeform.sectionId.$error
                  },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "sectionId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.sectionData),function(item){return _c('option',{key:item.idx,domProps:{"value":item.idx}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),(_vm.typesubmit && _vm.$v.typeform.sectionId.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.sectionId.required)?_c('span',[_vm._v("값을 선택해주세요.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-4 col-sm-4"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("강의")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.postId),expression:"typeform.postId"}],staticClass:"form-control mg-r-5",class:{
                    'is-invalid': _vm.typesubmit && _vm.$v.typeform.postId.$error
                  },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "postId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.postData),function(item){return _c('option',{key:item.idx,domProps:{"value":item.idx}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0),(_vm.typesubmit && _vm.$v.typeform.postId.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.postId.required)?_c('span',[_vm._v("값을 선택해주세요.")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-sm-4"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("작성자(닉네임)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.mbName),expression:"typeform.mbName"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.typesubmit && _vm.$v.typeform.mbName.$error
                  },attrs:{"type":"text","disabled":"true"},domProps:{"value":(_vm.typeform.mbName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.typeform, "mbName", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.typeform.mbName.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.mbName.required)?_c('span',[_vm._v(" 값을 입력해주세요. ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-4 col-sm-4"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("작성자(아이디)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.mbId),expression:"typeform.mbId"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.typesubmit && _vm.$v.typeform.mbId.$error
                  },attrs:{"type":"text","disabled":"true"},domProps:{"value":(_vm.typeform.mbId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.typeform, "mbId", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.typeform.mbId.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.mbId.required)?_c('span',[_vm._v(" 값을 입력해주세요. ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-4 col-sm-4"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("등록일")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.regDate),expression:"typeform.regDate"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.typesubmit && _vm.$v.typeform.regDate.$error
                  },attrs:{"type":"text"},domProps:{"value":(_vm.typeform.regDate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.typeform, "regDate", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.typeform.regDate.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.regDate.required)?_c('span',[_vm._v(" 값을 입력해주세요. ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-4 col-sm-4"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("오픈여부")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.openYn),expression:"typeform.openYn"}],staticClass:"form-control mg-r-5",class:{
                    'is-invalid': _vm.typesubmit && _vm.$v.typeform.openYn.$error
                  },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "openYn", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"Y"}},[_vm._v("Y")]),_c('option',{attrs:{"value":"N"}},[_vm._v("N")])]),(_vm.typesubmit && _vm.$v.typeform.openYn.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.openYn.required)?_c('span',[_vm._v(" 값을 입력해주세요. ")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("질문")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.title),expression:"typeform.title"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.typesubmit && _vm.$v.typeform.title.$error
                  },attrs:{"type":"text"},domProps:{"value":(_vm.typeform.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.typeform, "title", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.typeform.title.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.title.required)?_c('span',[_vm._v(" 값을 입력해주세요. ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("상세 내용")]),_c('CkeditorNuxtBasic',{attrs:{"config":_vm.config},model:{value:(_vm.typeform.desc),callback:function ($$v) {_vm.$set(_vm.typeform, "desc", $$v)},expression:"typeform.desc"}})],1)]),_c('div',{staticClass:"col-12 text-center"},[_c('button',{staticClass:"btn btn-dark mr-2",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("목록으로")]),_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.goCommunity(_vm.pageData.idx)}}},[_vm._v("바로가기")])])])])])]),_c('h5',[_vm._v("답변수 "+_vm._s(_vm.replyData.length))]),_vm._l((_vm.replyData),function(item){return _c('QnaReplyList',{key:item.idx,attrs:{"item":item},on:{"reply-reload":function($event){return _vm.$emit('reply-reload')}}})})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }